import React from 'react';
import { Description, Title } from 'shared-components/ModalsContent';
import { Form } from 'antd';
import { Item, Input } from 'components/Form';
import { numericTenSymbols } from 'lib/regex/validations';

interface IProps {
  adsAccount: string;
  setAdsAccount: (value: string) => void;
  customerListId: string;
  setCustomerListId: (value: string) => void;
}

export default ({ adsAccount, setAdsAccount, customerListId, setCustomerListId }: IProps) => (
  <>
    <Title>Select Customer segment list</Title>
    <Description>
      Please choose Google Ads Account and define Customer Segment list the data will be saved to.
    </Description>
    <Form layout="vertical" style={{ padding: '16px' }}>
      <Item
        label="Google Ads Account (CustomerID)"
        required
        name="customerId"
        rules={[
          { required: true, message: 'Please enter your Google Ads Customer ID.' },
          numericTenSymbols,
        ]}
      >
        <Input
          placeholder="Enter Google Ads Customer ID"
          value={adsAccount}
          onChange={(e) => setAdsAccount(e.target.value)}
        />
      </Item>
      <Item
        label="Customer List ID"
        name="customerListId"
        required
        rules={[
          { required: true, message: 'Please enter your Customer List ID.' },
          numericTenSymbols,
        ]}
      >
        <Input
          placeholder="Enter Customer List ID"
          value={customerListId}
          onChange={(e) => setCustomerListId(e.target.value)}
        />
      </Item>
    </Form>
  </>
);
