import { AxiosResponse } from 'axios';
import { ICredentials, IEditGCMFields, IGCMConfig } from 'lib/interfaces/googleCustomerMatch';
import { AbstractApiService } from './abstractApiService';
import { IntegrationApiAxios } from './axiosInstance';

class GCMIntegrationApiService extends AbstractApiService {
  getCredentials(code: string): Promise<AxiosResponse<ICredentials>> {
    return IntegrationApiAxios.post('gcm/configs/credentials', { code }, this.getApiHeaders());
  }

  createConfig(body: Partial<IGCMConfig>): Promise<AxiosResponse<IGCMConfig>> {
    return IntegrationApiAxios.post('gcm/configs', body, this.getApiHeaders());
  }

  getConfigs(pacUuid: string): Promise<AxiosResponse<IGCMConfig[]>> {
    return IntegrationApiAxios.get(`gcm/configs?pacUuid=${pacUuid}`, this.getApiHeaders());
  }

  editConfig(configUuid: string, body: IEditGCMFields): Promise<AxiosResponse<IGCMConfig>> {
    return IntegrationApiAxios.patch(`gcm/configs/${configUuid}`, body, this.getApiHeaders());
  }

  delete(configUuid: string): Promise<AxiosResponse> {
    return IntegrationApiAxios.delete(`gcm/configs/${configUuid}`, this.getApiHeaders());
  }
}

export default new GCMIntegrationApiService();
